<template>
  <CompositionForm
    :type="'create'"
    :composition="composition"
    @save="createComposition"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    CompositionForm: () => import('./CompositionForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    isFormValid: true,
    askToLeave: true,
    composition: {
      status: true,
      name: null,
      code: null,
      description: null,
      vendor_id: 1,
    },
  }),
  methods: {
    createComposition() {
      this.askToLeave = false
      const formData = new FormData()
      const compositionKeys = Object.keys(this.composition)
      const compositionValues = Object.values(this.composition)

      for (let i = 0; i < compositionKeys.length; i++) {
        if (compositionValues[i] !== null) {
          formData.append(compositionKeys[i], compositionValues[i])
        }
      }

      console.log(formData)
      if (this.isFormValid) {
        this.$api
          .post('product_compositions', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Composição cadastrado com sucesso',
              confirm: () => {
                this.$router.push({ name: 'composition' })
              },
            })
          })
          .catch(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar cadastrar este Composição',
              caption: 'Verifique se os campos estão preenchidos corretamente.',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar este Composição',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
